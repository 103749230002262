body {
  margin: 0;
  color: #4e4e4e;
}


* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-size: 0.9em;
}

/* li {
  font-size: 0.85em;
} */

.contentObjectMain{
  display: flex;
  justify-content: space-evenly;
}
